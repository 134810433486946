export default {
  // 회원 정보
  // levels = customClaims와 연결되는 권한 정보
  // userClass = 개인 학생 기업 구분정보
  // userInterface = 회원 DB 저장 type
  levels: [
    { text: "개발자", value: 0, icon: "tool", color: "orange" },
    {
      text: "관리자",
      value: 1,
      icon: "shield",
      color: "var(--v-primary-base)",
    },
    { text: "운영자", value: 2, icon: "user-plus", color: "green" },
    { text: "회원", value: 3, icon: "user", color: "black" },
    { text: "손님", value: 4, icon: "user", color: "black" },
  ],
  userClass: [
    { text: "개인회원", icon: "mdi-account", color: "black" },
    { text: "학생회원", icon: "mdi-account", color: "black" },
    { text: "기업회원", icon: "mdi-account", color: "black" },
  ],
  userInterface: {
    photoURL: "",
    uid: "",
    email: "",
    emailVerified: false,
    nickName: "",
    displayName: "",
    disabled: true,
    createdAt: "",
    updatedAt: "",
    visitedAt: "",
    visitCount: 0,
    level: 3,
    countryCode: "",
    phoneNumber: "",
    makerInfo: { makerId: "", makerSpace: "" },
    companyInfo: {
      companyId: "",
      companyName: "",
      cheifName: "",
      email: "",
    },
    agree: { rule: false, userInfo: false, marketing: true },
    adminInfo: "",
    // 통계
  },
  // 주문 관련 정보
  // orderFormInterface = 주문서 DB 저장 type
  orderFormInterface: {
    // 기본 정보
    id: "",
    createdAt: new Date(),
    // 주문자 정보
    uid: "",
    email: "",
    displayName: "",
    phoneNumber: "",
    userClass: 0,
    schoolInfo: {},
    companyInfo: {},
    // 주문 정보
    purpose: "", // 견적 제목
    environment: "", // 사용 환경
    memo: "", // 참고 사항
    dueAt: "", // 희망 납기
    workspace: "메이드올 대구지사", // 작업 장소
    file: [],
    // 작업 정보
    status: 0, // 진행 단계
    statusChanged: [], // 진행 단계 변경 이력
    shipAt: "", // 출고(예정)일
    confirmed: false,
    confirmedAt: null,
    updatedAt: new Date(),
    finishedAt: null,
    productCost: 3500,
    workspaceCost: 3500,
    paymentMethod: 0,
    shippingMethod: 0,
    printMemo: "",
    printFile: [],
    docs: {
      orderDocPath: "",
      orderDocDownloadUrl: "",
      quoteFilePath: "",
      quoteDownloadUrl: "",
      print: false,
    },
  },
  newOrderFormInterface: {
    statusChanged: [], // 진행 단계 변경 이력
    id: "", //Order ID
    createdAt: new Date(), //접수 시간
    paidAt: null, // 결제 일시
    status: "Draft", // Draft(작성 중), Pending(주문서 접수), Checking(담당자 확인중), Hold(고객 수정 요청), Ready(작업 배정||결제확인), Progress(작업 중), Finished(작업 완료), Shipped(출고), Completed(주문 완료)
    uid: "", // 회원은 uid, 비회원은 ""
    phoneNumber: "", // 접수자 연락처
    displayName: "", // 접수자 성함
    makerId: "", // 접수자 추가 정보
    purpose: "", // 사용 목적 및 제목
    workspace: "메이드올 대구지사", // 작업 장소
    memo: "", // 참고사항
    modelingMemo: "", //스케치 업로드 작업 내용
    printingPrice: 0,
    modelingPrice: 0,
    shippingPrice: 3500, //
    discountPrice: 0, // 할인 금액
    totalPrice: 3500,
    address: "", // 배송 주소
    trackingCode: "", // 운송장 번호
    printmemo: "", // 작업자 참고용 메모
    dueAt: null, // 희망 출고일
    shipAt: null, // 출고 일자
    shippingMethod: "택배", // 택배, 고속버스, 방문수령, 퀵
    paymentMethod: "네이버 결제", // 네이버 결제, 현금 결제, 세금계산서
    updatedAt: null,
    marketingAgree: false,
    docs: {
      quoteUrl: "",
      receiptUrl: "",
      orderUrl: "",
    },
    file: [
      // {
      //   id: "", // File ID
      //   name: "", // FileName
      //   url: "", // downloadUrl
      //   previewImg: "", // image Url
      //   print: true, // 출력 파일 여부
      //   dimensions: {},
      //   option: [
      //     {
      //       method: "", // printMethod
      //       material: "", // printMaterial
      //       color: "", // printColor
      //       layer: "", // layer Height
      //       wall: "", // wall Count
      //       infill: "", // infill Density
      //       totalQty: 0, // print Quantity
      //       finishQty: 0, // 완료된 수량
      //       price: 0, // 옵션 가격
      //     },
      //   ],
      // },
    ],
    resultImg: [
      // {
      //   name: "",
      //   url: "",
      //   type: "",
      //   previewImg: "",
      //   uploader: "",
      //   uploadedAt: "",
      // },
    ],
    modelingFile: [
      // { name: "", url: "", size: "", type: "", previewImg: "" }
    ],
    // 아래는 삭제 대상
    // modelingResultFile: [
    //   // { name: "", url: "", size: "", type: "", previewImg: "" }
    // ],
    // modelingDate: "",
    // modelingExpectPrice: "",
  },
  fileInterface: {
    id: "", // File ID
    uid: "", // uploader ID
    name: "", // FileName
    url: "", // downloadUrl
    type: "", // fileType
    dimensions: { x: "", y: "", z: "" },
    volume: "",
    area: "",
    tags: [""],
    previewImg: "", // image Url
    size: "", // FileSize
    uploadAt: new Date(), // 처음 업로드 된 시간
    updatedAt: "", // 출력 또는 수정된 마지막 일자
    memo: "", // 작업 참고용 메모
    orders: [
      {
        id: "", // order ID
        createdAt: "", // order Created Date
        option: [
          {
            method: "", // printMethod
            material: "", // printMaterial
            color: "", // printColor
            layer: "", // layer Height
            wall: "", // wall Count
            infill: "", // infill Density
            qty: 0, // print Quantity
            price: 0, // option Price
          },
        ],
      },
    ],
  },
  newPrintOption: {
    FDM: {
      name: "FDM (필라멘트) 3D프린팅",
      description: "가장 저렴하고 일반적인 프린팅 방식",
      materials: {
        PLA: {
          description: "가장 저렴한 기본 소재",
          colors: {
            White: { hex: "#FFFFFF" },
            Natural: { hex: "#FDF5E6" },
            Black: { hex: "#000000" },
            Gray: { hex: "#808080" },
            Silver: { hex: "#C0C0C0" },
            DarkGreen: { hex: "#008716" },
            Green: { hex: "#00CE00" },
            WarmBeige: { hex: "#FFE4CB" },
            Yellow: { hex: "#FFFF00" },
            Orange: { hex: "#FF5D21" },
            Red: { hex: "#EA0000" },
            SoftPink: { hex: "#FFB6C1" },
            Pink: { hex: "#FF1493" },
            Violet: { hex: "#7818D0" },
            SkyBlue: { hex: "#8AD2FF" },
            Blue: { hex: "#0038FD" },
            Navy: { hex: "#000080" },
            Gold: { hex: "#FFBF1D" },
            Copper: { hex: "#AF2626" },
            Brown: { hex: "#854831" },
          },
          layer: ["0.2", "0.16", "0.12"],
          wall: ["2", "3", "4", "5"],
          infill: ["15%", "20%", "50%", "80%", "100%"],
        },
        PETG: {
          description: "질긴 소재, 내충격성",
          colors: {
            White: { hex: "#FFFFFF" },
            Red: { hex: "#EA0000" },
            Black: { hex: "#000000" },
          },
          layer: ["0.2", "0.16", "0.12"],
          wall: ["2", "3", "4", "5"],
          infill: ["15%", "20%", "50%", "80%", "100%"],
        },
        ABS: {
          description: "내열성, 내화학성",
          colors: {
            White: { hex: "#FFFFFF" },
            Black: { hex: "#000000" },
            Gray: { hex: "#808080" },
            Red: { hex: "#EA0000" },
            Blue: { hex: "#0038FD" },
            Yellow: { hex: "#FFFF00" },
          },
          layer: ["0.2", "0.16", "0.12"],
          wall: ["2", "3", "4", "5"],
          infill: ["15%", "20%", "50%", "80%", "100%"],
        },
        "TPU 64D": {
          description: "단단한 고무 느낌",
          colors: {
            White: { hex: "#FFFFFF" },
            Black: { hex: "#000000" },
          },
          layer: ["0.2", "0.16"],
          wall: ["2", "3", "4", "5"],
          infill: ["15%", "20%", "50%", "80%", "100%"],
        },
        "TPU 95A": {
          description: "일반적인 고무 느낌",
          colors: {
            White: { hex: "#FFFFFF" },
            Black: { hex: "#000000" },
            Yellow: { hex: "#FFFF00" },
          },
          layer: ["0.2", "0.16"],
          wall: ["2", "3", "4", "5"],
          infill: ["15%", "20%", "50%", "80%", "100%"],
        },
        "TPU 85A": {
          description: "물렁한 실리콘 느낌",
          colors: {
            Black: { hex: "#000000" },
          },
          layer: ["0.2", "0.16"],
          wall: ["2", "3", "4", "5"],
          infill: ["15%", "20%", "50%", "80%", "100%"],
        },
      },
    },
    SLA: {
      name: "SLA (레진) 3D프린팅",
      description: "높은 품질, FDM 대비 약 10배 높은 금액",
      materials: {
        "ABS-Like": {
          description: "ABS와 유사한 레진",
          colors: {
            Ivory: { hex: "#fcfced" },
            Black: { hex: "#000000" },
          },
        },
      },
    },
  },
  printOption: [
    {
      method: "상담 후 결정",
      material: [
        {
          name: "방식 선택 필",
          color: [{ name: "소재 선택 필", color: "#FFFFFF" }],
        },
      ],
    },
    {
      method: "FDM (일반 필라멘트 방식)",
      material: [
        {
          name: "PLA (저렴하고 깔끔한 일반 소재)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Natural(반투명)", color: "#FDF5E6" },
            { name: "Black", color: "#000000" },
            { name: "Gray", color: "#808080" },
            { name: "Silver", color: "#C0C0C0" },
            { name: "DarkGreen", color: "#008716" },
            { name: "Green", color: "#00CE00" },
            { name: "WarmBeige", color: "#FFE4CB" },
            { name: "Yellow", color: "#FFFF00" },
            { name: "Orange", color: "#FF5D21" },
            { name: "Red", color: "#EA0000" },
            { name: "SoftPink", color: "#FFB6C1" },
            { name: "Pink", color: "#FF1493" },
            { name: "Violet", color: "#7818D0" },
            { name: "SkyBlue", color: "#8AD2FF" },
            { name: "Blue", color: "#0038FD" },
            { name: "Navy", color: "#000080" },
            { name: "Gold", color: "#FFBF1D" },
            { name: "Copper", color: "#AF2626" },
            { name: "Brown", color: "#854831" },
          ],
        },
        {
          name: "PETG (내충격성 eg.기계 부품)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Black", color: "#000000" },
          ],
        },
        {
          name: "ABS (내열성 eg.차량 내부)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Black", color: "#000000" },
            { name: "Red", color: "#EA0000" },
          ],
        },
        {
          name: "TPU (말랑한 재질)",
          color: [
            { name: "White", color: "#FFFFFF" },
            { name: "Black", color: "#000000" },
            { name: "Red", color: "#EA0000" },
            { name: "Blue", color: "#0038FD" },
          ],
        },
      ],
    },
    {
      method: "SLA (레진, FDM 금액의 약 10배)",
      material: [
        {
          name: "ABS-Like (산업용)",
          color: [
            { name: "Ivory", color: "#fcfced" },
            { name: "Black", color: "#000000" },
          ],
        },
        {
          name: "아크릴레진 (피규어용)",
          color: [{ name: "Random", color: "#FFFFFF" }],
        },
      ],
    },
  ],
  orderStatusItem: [
    { title: "주문 접수", icon: "upload-cloud" },
    { title: "상담 중", icon: "headphones" },
    { title: "상담 완료", icon: "user-check" },
    { title: "결제 확인", icon: "credit-card", color: "orange" },
    { title: "작업 진행", icon: "tool", color: "green" },
    { title: "작업 완료", icon: "package", color: "var(--v-primary-base)" },
    { title: "완료", icon: "send" },
  ],
  orderPayment: [
    { title: "스마트스토어", icon: "credit-card", color: "green" },
    { title: "현금 결제", icon: "dollar-sign", color: "orange" },
    { title: "세금계산서(선불)", icon: "file-text" },
    { title: "세금계산서(후불)", icon: "file-text" },
  ],
  shippingMethod: [
    {
      title: "CJ대한통운",
      icon: "package",
      color: "blue",
      price: 3500,
    },
    { title: "우체국 등기", icon: "mail", color: "red", price: 4500 },
    {
      title: "우체국 택배",
      icon: "package",
      color: "red",
      price: 5500,
    },
    { title: "고속버스탁송", icon: "truck", price: 20000 },
    { title: "오토바이퀵", icon: "user", price: 0 },
    { title: "방문수령", icon: "user", price: 0 },
    { title: "직접전달", icon: "user", price: 0 },
    { title: "무료(합)배송", icon: "user", price: 0 },
  ],
  makerSpace: [
    "메이드올 대구지사",
    "경희대학교 국제캠퍼스",
    "더엠제이",
    "쓰리디솔루션",
  ],
  newmakerSpace: {
    "메이드올 대구지사": {
      options: {
        FDM: {
          PLA: [
            "White",
            "Natural",
            "Black",
            "Gray",
            "Silver",
            "DarkGreen",
            "Green",
            "WarmBeige",
            "Yellow",
            "Orange",
            "Red",
            "SoftPink",
            "Pink",
            "Violet",
            "SkyBlue",
            "Blue",
            "Navy",
            "Gold",
            "Copper",
            "Brown",
          ],
          PETG: ["White", "Red", "Black"],
          ABS: ["White", "Black", "Gray", "Red", "Blue", "Yellow"],
          "TPU 64D": ["White", "Black"],
          "TPU 95A": ["White", "Black", "Yellow"],
          "TPU 85A": ["Black"],
        },
        SLA: { "ABS-Like": ["Ivory", "Black"] },
      },
      shippingMethods: ["택배", "고속버스", "방문수령", "퀵"],
    },
    "경희대학교 국제캠퍼스": {
      options: {
        FDM: {
          PLA: ["White"],
        },
      },
      shippingMethods: ["방문수령"],
    },
  },
  schoolList: ["경희대학교 국제캠퍼스"],
  pressInterface: {
    id: "",
    title: "",
    subtitle: "",
    url: "",
    imageURL: "",
    locked: true,
    filePath: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    uid: "",
  },
};
